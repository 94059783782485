<script>
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import rrulePlugin from '@fullcalendar/rrule';
import bootstrapPlugin from "@fullcalendar/bootstrap";
import listPlugin from "@fullcalendar/list";
import { INITIAL_EVENTS, createEventId } from "../calendario/event-utils";
import ptBrLocale from "@fullcalendar/core/locales/pt-br";
import { http } from "@/helpers/easyindustriaapi/config";
import moment from "moment";
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";

export default {
  props: {
    vet_responsible: { type: Array },
    vet_specialtys: { type: Array },
  },
  components: {
    FullCalendar,
    Multiselect,
  },

  data() {
    return {
      currentEmpresa: {},
      currentUser: {},
      vtitle:null,
      input_title_bol:false,
      specialtys: [],
      agenda: {},
      objeto_geral: null,
      modal_tittle_editar: null,
      profissionais: [],
      professional: null,
      select_prof: null,
      responsaveis: [],
      select_paciente: null,
      pacientes: [],
      paciente: null,
      modal_edit_del: false,
      vet_registros: [],
      vet_env_back: [],
      abr_div: false,
      tp_consulta: null,
      resp: null,
      select_inf: null,
      titulo: null,
      nome: null,
      showModal: false,
      calendarOptions: {
        plugins: [
          rrulePlugin,
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin, // needed for dateClick
          bootstrapPlugin,
          listPlugin,
        ],
        locales: [ptBrLocale],
        // timeZone: 'America/Sao_Paulo',
        headerToolbar: {
          left: "prevYear,prev,next,nextYear today",
          center: "title",
          // mostra os botões de mês, semanas dias e listagem dia e mês
          right: "dayGridMonth,timeGridWeek,timeGridDay, listWeek,listDay",
        },
        initialView: "dayGridMonth",
        initialEvents: INITIAL_EVENTS, // alternatively, use the `events` setting to fetch from a feed
        initialDate: new Date(),
        eventColor: null, // Azul
        eventTextColor: "#FFFFFF", // Branco
        droppable: false,
        selectable: false,
        selectMirror: true,
        dayMaxEvents: true,
        weekends: true,
        select: this.handleDateSelect,
        eventClick: this.handleEventClick,
        eventsSet: this.handleEvents,
        eventDrop: this.handleEventDrop,
        eventResize: this.handleEventResize,
        navLinks: true, // can click day/week names to navigate views
      },
      currentEvents: [],
      toogleIntructions: false,
    };
  },
created() {
    this.currentUser    = JSON.parse(localStorage.getItem('user'));
    this.currentEmpresa = JSON.parse(localStorage.getItem('currentEmpresa'));
    this.specialtys     = this.vet_specialtys;
  },
  mounted() {
    this.inciando_valores();
  },
  computed: {
    axiosParams() {
      const params = new URLSearchParams();
      params.append("empresa_id", this.currentEmpresa.id);

      if (this.paciente) {
        params.append("pacient_id", this.paciente.id);
      }

      return params;
    },
    dateAgrement() {
      const date = new Date();
      date.setMinutes(date.getMinutes() + 40);
      return date;
    },
  },
  methods: {
    alter_title(event){
      console.log(event.target.value);
      console.log(this.vtitle);

      if(this.vtitle){
        this.input_title_bol = true;
      } else {
        this.input_title_bol = false;
      }
        
    },
    seleciona_specialty(event) {
      console.log(event);
      console.log(this.agenda.specialty);
    },
    async doPut(event) {
      // Objeto calendario
      let calendar = {};
      // Adicionando os dados do objeto
      calendar.id = event.id;
      calendar.desc = event.desc;
      calendar.empresa_id = this.currentEmpresa.id;
      calendar.end = moment(event.end).format("yyyy-MM-DDTHH:mm");
      calendar.professional_id = event.professional_id;
      calendar.specialty_id = event.specialty_id;
      calendar.pacient_id = event.pacient_id;
      calendar.start = moment(event.start).format("yyyy-MM-DDTHH:mm");
      calendar.title = event.title;

      // Exibe o SweetAlert com loader
      Swal.fire({
        title: "Aguarde...",
        text: "Estamos processando sua solicitação.",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading(); // Exibe o loader enquanto a requisição está em andamento
        },
      });

      const promisse = http.put(`/agenda/${calendar.id}?empresa_id=${this.currentEmpresa.id}`,calendar);

      return promisse
        .then(() => {
          Swal.close(); // Fecha o loader
          this.makeToast("success", "Atendimento reagendado com sucesso!");
          this.getData();
        })
        .catch((error) => {
          Swal.close(); // Fecha o loader
          console.log(error);
          // Tratamento de erro 500
          if (error.response && error.response.status === 406) {
            // Exibe mensagem de erro específica para o código 406
            let timerInterval;
            Swal.fire({
              icon: "error",
              title: "Erro de Preenchimento!",
              text: error.response.data,
              timer: 3000,
              timerProgressBar: true,
              didOpen: () => {
                Swal.showLoading();
                const timer = Swal.getPopup().querySelector("b");
                timerInterval = setInterval(() => {
                  timer.textContent = `${Swal.getTimerLeft()}`;
                }, 100);
              },
              willClose: () => {
                clearInterval(timerInterval);
              },
            }).then((result) => {
              /* Read more about handling dismissals below */
              if (result.dismiss === Swal.DismissReason.timer) {
                console.log("I was closed by the timer");
                this.modal_edit_del = true;
              }
            });
          } else {
            // Tratamento genérico de outros erros
            Swal.fire({
              icon: "error",
              title: "Erro!",
              text: "Houve um erro ao processar sua requisição. Tente novamente.",
            });
          }
        });
    },
    seleciona_paciente(objeto) {
      console.log(objeto.target.value);

      let paci = this.pacientes.find((p) => p.id == objeto.target.value);
      this.agenda.titulo = paci.nome;
    },
    seleciona_prof(objeto) {
      console.log(objeto);
      let prof = this.profissionais.find((p) => p.id == objeto.target.value);
      console.log("Objeto Professional");
      console.log(prof);
      this.agenda.titulo = `${this.agenda.titulo} | ${prof.name}`;
    },

    fechar_modal_edit() {
      this.modal_edit_del = false;
    },
    // evento para redimensionar o agendas ja existentes
    handleEventResize(eventResizeInfo) {
      // Lógica para atualizar a duração de um evento ao redimensioná-lo
      console.log("Evento redimensionado:", 'eventResizeInfo');
      eventResizeInfo.revert();
      // // Atualize o evento no backend ou localmente
      // let objeto_update = {
      //   id: eventResizeInfo.event.id,
      //   title: eventResizeInfo.event.title,
      //   start: this.isCustomDateFormat(eventResizeInfo.event.start)
      //     ? this.convertToDateTimeLocal(eventResizeInfo.event.start)
      //     : eventResizeInfo.event.start,
      //   end: this.isCustomDateFormat(eventResizeInfo.event.end)
      //     ? this.convertToDateTimeLocal(eventResizeInfo.event.end)
      //     : eventResizeInfo.event.end,
      //   empresa_id: eventResizeInfo.event.extendedProps.empresa_id,
      //   desc: eventResizeInfo.event.extendedProps.description,
      //   pacient_id: eventResizeInfo.event.extendedProps.pacient_id,
      //   professional_id: eventResizeInfo.event.extendedProps.professional_id,
      //   specialty_id: eventResizeInfo.event.extendedProps.specialty_id,
      // };
      // console.log("Mostrando Obejto Redimesionamento");
      // console.log(objeto_update);
      // //
      // this.doPut({ ...objeto_update });
    },
    handleDateClick(arg) {
      console.log("Clicou HandleDateClick");
      console.log(arg);
    },
    handleEventDrop(info) {
      console.log("Dropando evento", info);
      info.revert();
      // let objeto_update = {
      //   id: info.event.id,
      //   title: info.event.title,
      //   start: this.isCustomDateFormat(info.event.start)
      //     ? this.convertToDateTimeLocal(info.event.start)
      //     : info.event.start,
      //   end: this.isCustomDateFormat(info.event.end)
      //     ? this.convertToDateTimeLocal(info.event.end)
      //     : info.event.end,
      //   empresa_id: info.event.extendedProps.empresa_id,
      //   desc: info.event.extendedProps.description,
      //   pacient_id: info.event.extendedProps.pacient_id,
      //   professional_id: info.event.extendedProps.professional_id,
      //   specialty_id: info.event.extendedProps.specialty_id,
      // };

      // // Atualize seu backend ou faça a lógica para ajustar a nova data
      // console.log(`Evento movido para: ${info}`);
      // this.doPut({ ...objeto_update });
      // // Aqui você poderia fazer uma chamada para atualizar o evento no banco de dados, por exemplo.
    },
    // evento para editar e exluir
    handleEventClick(clickInfo) {
        this.viewEvent(clickInfo.event);
    },
    handleEvents(events) {
      this.currentEvents = events;
      console.log('handleEvents');
    },
    handleDateSelect(selectInfo) {
      console.log(selectInfo);
      // this.abrir_modal(selectInfo);
      // const date = new Date(selectInfo.start);
      // date.setMinutes(date.getMinutes() + 40);
      // this.agenda.dt_fin = this.convertToDateTimeLocal(date);
      // this.agenda.dt_ini = this.convertToDateTimeLocal(selectInfo.start);
    },
    handleWeekendsToggle() {
      console.log("handleWeekendsToggle");
      this.calendarOptions.weekends = !this.calendarOptions.weekends; // update a property
    },

    inciando_valores() {
      this.getData();
    },
    abrir_modal(selectInfo) {
      this.showModal = true;
      this.select_inf = selectInfo;
    },
    fechar_modal2() {
      this.showModal = false;
    },
    convertToFullCalendarDate(datetimeLocal) {
      // O formato recebido é "YYYY-MM-DDTHH:MM:SS", então apenas criar o objeto Date
      return new Date(datetimeLocal);
    },
    convertToDateTimeLocal(date) {
      // Converte o objeto Date para o formato "YYYY-MM-DDTHH:MM:SS"
      const year = date.getFullYear();
      const month = ("0" + (date.getMonth() + 1)).slice(-2); // Adiciona o zero à esquerda se necessário
      const day = ("0" + date.getDate()).slice(-2);
      const hours = ("0" + date.getHours()).slice(-2);
      const minutes = ("0" + date.getMinutes()).slice(-2);
      const seconds = ("0" + date.getSeconds()).slice(-2);

      // Formata como 'YYYY-MM-DDTHH:MM:SS'
      let hora_tot = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
      return hora_tot;
    },
    fechar_modal() {
      let title = this.agenda.titulo;
      let calendarApi = this.select_inf.view.calendar;
      console.log("Monstrando data, hora, minutos e segundos");
      console.log(this.select_inf.start);
      console.log(this.select_inf.end);
      console.log(this.convertToFullCalendarDate(this.agenda.dt_ini));
      console.log(this.convertToFullCalendarDate(this.agenda.dt_fin));

      calendarApi.unselect(); // clear date selection

      if (title) {
        calendarApi.addEvent({
          id: createEventId(),
          title,
          start: this.convertToFullCalendarDate(this.agenda.dt_ini),
          end: this.convertToFullCalendarDate(this.agenda.dt_fin),
          extendedProps: {
            description: this.agenda.desc,
            pacient_id: this.agenda.pacient_id,
            professional_id: this.agenda.professional_id,
            specialty_id: this.agenda.specialty,
            // Adicionando empresa_id em extendedProps
          },
        });
      }
      this.vet_env_back = [];
      this.currentEvents.forEach((e) => {
        let objeto = {
          title: e.title,
          start: this.isCustomDateFormat(e.start)
            ? this.convertToDateTimeLocal(e.start)
            : e.start,
          end: this.isCustomDateFormat(e.end)
            ? this.convertToDateTimeLocal(e.end)
            : e.end,
          desc: e.extendedProps.description,
          pacient_id: e.extendedProps.pacient_id,
          professional_id: e.extendedProps.professional_id,
          specialty_id: e.extendedProps.specialty_id,
        };
        console.log("starts");
        console.log(e.start);
        this.vet_env_back.push({ ...objeto });
      });

      console.log("Monstrando Vet antes de envair para o backend");

      console.log(this.vet_env_back);

      if (this.vet_env_back.length > 0) {
        let tam = this.vet_env_back.length;
        this.doPost(this.vet_env_back[tam - 1]);
      }
    },
    isCustomDateFormat(dateString) {
      // Verifica se a string corresponde ao formato: Thu Sep 19 2024 00:00:00 GMT-0300 (Horário Padrão de Brasília)
      const regex =
        /^[A-Za-z]{3} [A-Za-z]{3} \d{2} \d{4} \d{2}:\d{2}:\d{2} GMT[+-]\d{4} \([A-Za-zÀ-ÿ\s]+\)$/;
      const isMatch = regex.test(dateString);

      return isMatch;
    },
    makeToast(variant = null, message = "Error generic") {
      this.counter++;
      this.$bvToast.toast(message, {
        title: `Notificação`,
        toaster: "b-toaster-bottom-right",
        variant: variant,
        solid: true,
        appendToast: true,
      });
    },
    gerando_vetor_calentario() {
      let vet = [...this.vet_registros];
      let vet_aux = [];

      vet.forEach((e) => {
        console.log(e.end);
        let objeto = {
          id: e.id,
          title: e.pacient.nome,
          start: moment(e.start).format("yyyy-MM-DD"),
          end: (e.end) ? moment(e.end).format("yyyy-MM-DD") : null,
          startTime: e.start_time,
          endTime: e.end_time,
          daysOfWeek: [e.day_of_week],
          startRecur: e.start_recur,
          endRecur: e.end_recur,
          color: (e.professional.color) ? e.professional.color : "#0000ff", // Cor de fundo azul
          textColor: (e.professional.color) ? this.lumine(e.professional.color) : '#ffffff', // Texto branco
          extendedProps: {
            empresa_id: e.empresa_id,
            professional: e.professional,
            pacients: e.pacient,
            pacient_id: e.pacient_id,
            professional_id: e.professional_id,
            specialty_id: e.specialty_id,
            description: e.desc,
            atendimento_id: e.atendimento_id,
            recurrences: e.recurrences
          },
          display: "block",
        };

        // Adicionando a rrule para o evento
        if (e.recurrence.toUpperCase() == 'MONTHLY')
        {
          objeto.rrule = {
            freq: e.recurrence,
            interval: 1,
            dtstart:  e.start,
            until: (e.end_recur) ? moment(e.end_recur).format('YYYY-MM-DD') : null, // data final da recorrência
            bymonthday: parseInt(moment(e.start).format('DD')), // específico para o dia 15
          }
        } // end recurrency monthly

        if (e.recurrence.toUpperCase() == 'YEARLY')
        {
          objeto.rrule = {
            freq: 'YEARLY',
            interval: 1,
            dtstart: e.start,
            until: (e.end_recur) ? moment(e.end_recur).format('YYYY-MM-DD') : null, // data final da recorrência
            bymonth: parseInt(moment(e.start).format('MM')), // específico para o mês de março
            bymonthday: parseInt(moment(e.start).format('DD')), // específico para o dia 15
          }
        } // end recurrency year

        if (e.professionals && e.professionals.length > 0)
        {
          objeto.professionals = e.professionals;
          objeto.color = '#000000';
          objeto.textColor = '#FFFFFF';
          objeto.title = "REUNIÃO - " + e.pacient.nome;
          objeto.meet = true;
        }

        vet_aux.push({ ...objeto });
      }); // end foreach
      console.log(vet_aux);

      this.calendarOptions.events = [...vet_aux];
      this.abr_div = false;
      setTimeout(() => {
        this.abr_div = true;
        return this.calendarOptions.events;
      }, 100);
    },
    getData() {
      // Exibe o SweetAlert com loader
      Swal.fire({
        title: "Aguarde...",
        text: "Carregando atendimentos da agenda...",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading(); // Exibe o loader enquanto a requisição está em andamento
        },
      });

      const promisse = http.get("/agenda/profissional", { params: this.axiosParams });
      return promisse
        .then((response) => {
          this.vet_registros = response.data.agendas;
          this.pacientes     = response.data.pacients;
          this.responsaveis  = response.data.responsibles;
          this.gerando_vetor_calentario();
          return response.data;
        })
        .catch((error) => {
          console.log(error);
          let messageErro = error.response.data;

          switch (error.response.status) {
            case 406:
              this.makeToast(
                "danger",
                "Erro 406: " + messageErro.tipo
                  ? messageErro.tiponome
                  : messageErro.tpag
              );
              break;
            case 404:
              this.makeToast(
                "danger",
                "Erro 404: endpoint não encontrado ou servidor fora do ar"
              );
              break;
            default:
              this.makeToast("danger", error.message);
              break;
          }
        })
        .finally(() => {
          Swal.close(); // Fecha o loader
        });
    },
    async getData2() {
      try {
        let response = await http.get(
          "/agenda?empresa_id=" + this.currentEmpresa.id
        );

        if (response.status === 200) {
          this.vet_registros = response.data;
          console.log(this.vet_registros);
        }
      } catch (error) {
        let messageErro = error.response.data;

        switch (error.response.status) {
          case 406:
            this.makeToast(
              "danger",
              "Erro 406: " + messageErro.tipo
                ? messageErro.tiponome
                : messageErro.tpag
            );
            break;
          case 404:
            this.makeToast(
              "danger",
              "Erro 404: endpoint não encontrado ou servidor fora do ar"
            );
            break;
          default:
            this.makeToast("danger", error.message);
            break;
        }
      }
    },
    async doPost(event) {
      this.showModal = false;
      // Objeto calendario
      let calendar = {};
      // Adicionando os dados do objeto
      calendar.desc = event.desc;
      calendar.empresa_id = this.currentEmpresa.id;
      calendar.end = moment(event.end).format("yyyy-MM-DDTHH:mm");
      calendar.professional_id = event.professional_id;
      calendar.pacient_id = event.pacient_id;
      calendar.start = moment(event.start).format("yyyy-MM-DDTHH:mm");
      calendar.title = event.title;
      calendar.specialty_id = event.specialty_id;

      // Exibe o SweetAlert com loader
      Swal.fire({
        title: "Aguarde...",
        text: "Estamos processando sua solicitação.",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading(); // Exibe o loader enquanto a requisição está em andamento
        },
      });

      const promisse = http.post(
        "/agenda?empresa_id=" + this.currentEmpresa.id,
        calendar
      );
      return promisse
        .then(() => {
          this.successmsg();
          this.getData();
        })
        .catch((error) => {
          Swal.close(); // Fecha o loader
          console.log(error);
          // Tratamento de erro 500
          if (error.response && error.response.status === 406) {
            // Exibe mensagem de erro específica para o código 406
            let timerInterval;
            Swal.fire({
              icon: "error",
              title: "Erro de Preenchimento!",
              text: error.response.data,
              timer: 3000,
              timerProgressBar: true,
              didOpen: () => {
                Swal.showLoading();
                const timer = Swal.getPopup().querySelector("b");
                timerInterval = setInterval(() => {
                  timer.textContent = `${Swal.getTimerLeft()}`;
                }, 100);
              },
              willClose: () => {
                clearInterval(timerInterval);
              },
            }).then((result) => {
              /* Read more about handling dismissals below */
              if (result.dismiss === Swal.DismissReason.timer) {
                console.log("I was closed by the timer");
                this.showModal = true;
              }
            });
          } else {
            // Tratamento genérico de outros erros
            Swal.fire({
              icon: "error",
              title: "Erro!",
              text: "Houve um erro ao processar sua requisição. Tente novamente.",
            });
          }
        })
        .finally(() => {});
    },
    doDelete(calendar) {
      // Exibe o SweetAlert com loader
      Swal.fire({
        title: "Aguarde...",
        text: "Estamos processando sua solicitação.",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading(); // Exibe o loader enquanto a requisição está em andamento
        },
      });
      calendar.empresa_id = this.currentEmpresa.id;
      const promisse = http.delete(
        `/agenda/${calendar.id}?empresa_id=${this.currentEmpresa.id}`,
        calendar
      );

      return promisse
        .then(() => {
          Swal.close();
          this.fechar_modal_edit();
        })
        .catch((error) => {
          let timerInterval;
          Swal.fire({
            icon: "error",
            title: "Erro ao tentar Excluir!",
            text: error.response.data,
            timer: 3000,
            timerProgressBar: true,
            didOpen: () => {
              Swal.showLoading();
              const timer = Swal.getPopup().querySelector("b");
              timerInterval = setInterval(() => {
                timer.textContent = `${Swal.getTimerLeft()}`;
              }, 100);
            },
            willClose: () => {
              clearInterval(timerInterval);
            },
          }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.dismiss === Swal.DismissReason.timer) {
              console.log("I was closed by the timer");
              this.showModal = true;
            }
          });
        });
    },
    delete_obj_modal() {
      let objeto = this.objeto_geral; // clickinfo
      let objeto_delt = {
        id: objeto.event.id,
        title: objeto.event.title,
        start: objeto.event.start,
        end: objeto.event.end,
        empresa_id: objeto.event.extendedProps.empresa_id,
        pacient_id: objeto.event.extendedProps.pacient_id,
        professional_id: objeto.event.extendedProps.professional_id,
        specialty_id: objeto.event.extendedProps.specialty_id,
      };

      Swal.fire({
        title: "Deseja realmente excluir o Agendamento?",
        text: "'" + objeto.event.title + "'",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.close();
          objeto.event.remove();
          this.doDelete({ ...objeto_delt });
        }
      });
      console.log(objeto_delt);
    },
    seleciona_paciente2(objeto) {
      let paci = this.pacientes.find((p) => p.id == objeto);
      this.agenda.titulo = paci.nome;
    },
    seleciona_prof2(objeto) {
      let prof = this.profissionais.find((p) => p.id == objeto);
      this.agenda.titulo = this.agenda.titulo + "|" + prof.name;
    },
    update_obj_modal() {
      let objeto = this.objeto_geral;
      console.log(objeto);
      this.seleciona_prof2(this.select_prof);
      this.seleciona_paciente2(this.select_paciente);
      let objeto_delt = {
        id: objeto.event.id,
        title: this.agenda.titulo2,
        start: this.agenda.dt_ini,
        end: this.agenda.dt_fin,
        empresa_id: objeto.event.extendedProps.empresa_id,
        desc: this.agenda.desc,
        pacient_id: this.select_paciente,
        professional_id: this.select_prof,
        specialty_id: this.agenda.specialty,
      };
      console.log("Mostrnado Objeto delta");
      console.log(objeto_delt);
      this.doPut({ ...objeto_delt });
    },
    hideIntructions() {
      this.toogleIntructions = !this.toogleIntructions;
    },
    /**
     * Show successfull Save Dialog
     */
    successmsg() {
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Atendimento gravado!",
        showConfirmButton: false,
        timer: 1000,
      });
    },
    // Abrir evento clicado
    viewEvent(event) {
        console.log(event);
        const texHTML = `<strong>Início</strong>: ${moment(event.start).format("DD/MM/yyyy HH:mm")}
        <br><strong>Paciente</strong>: ${event.extendedProps.pacients.nome}
        ${(event.end) ? '<br><strong>Fim:</strong>' + moment(event.end).format("DD/MM/yyyy HH:mm") : ''}
        <br><strong>Descricão:</strong> ${(event.desc) ? event.desc : ''}`;

        Swal.fire({
            title: event.title,
            html: texHTML,
            confirmButtonText: "<i class='ri-checkbox-circle-line'></i> Gravar",
            confirmButtonColor: "#1cbb8c",
            focusConfirm: false,
            cancelButtonText: "<i class='ri-close-circle-line'></i> Cancelar",
            cancelButtonColor: "#ff0a36",
            showCancelButton: true,
            input: "textarea",
            inputValue: event.extendedProps.description,
            inputLabel: "Parecer",
            inputPlaceholder: "Paciente apresentou quadro...",
            inputAttributes: {
                "aria-label": "Digite um parecer"
            },
            showLoaderOnConfirm: true,
            preConfirm: async (parecer) => {
                if (!parecer) {
                    Swal.showValidationMessage("Por favor, preencha o parecer");
                }

                try {
                    const agendaIn = {
                        id: event.id,
                        title: event.title,
                        start: event.start,
                        end: event.end,
                        desc: parecer,
                        color: (parecer) ? '#1cbb8c' : '#ff0a36',
                        pacient_id: event.extendedProps.pacients.id,
                        professional_id: event.extendedProps.professional_id,
                        specialty_id: event.extendedProps.specialty_id
                    };

                    return await this.doPut({ ...agendaIn });
                } catch (error) {
                    Swal.showValidationMessage(`Erro ao gravar: ${error}`);
                }
            },
            allowOutsideClick: () => !Swal.isLoading()
        });
    },
    lumine(rgb) {
      let color = '#FFFFFF';
      var nib = rgb.split(''); 
      var r = parseInt(nib[1]+nib[2],16);
      var g = parseInt(nib[3]+nib[4],16);
      var b = parseInt(nib[5]+nib[6],16);
      var lumin = ( r * 299 + g * 587 + b * 114) / 1000;
      
      if (lumin > 128) {
        color = '#000000';
      }

      return color;
    },
      // Return the calculated text color
  },
};

</script>

<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <b-button
              v-b-toggle="'collapse-instructions'"
              class="m-1"
              variant="warning"
              >Instruções</b-button
            >
            <b-button
              v-b-toggle="'collapse-filters'"
              class="m-1"
              variant="primary"
              >Filtros</b-button
            >
            <b-collapse id="collapse-instructions" class="mt-1">
              <b-card>
                <div class="demo-app-sidebar-section">
                  <ul>
                    <li>
                      Selecione datas e você será solicitado a criar um novo
                      evento
                    </li>
                    <li>Arraste, solte e redimensione eventos</li>
                    <li>Clique em um evento para excluí-lo</li>
                  </ul>
                </div>
                <div class="demo-app-sidebar-section">
                  <label>
                    <input
                      type="checkbox"
                      :checked="calendarOptions.weekends"
                      @change="handleWeekendsToggle"
                    />
                    Adicionar Finais de Semana
                  </label>
                </div>
              </b-card>
            </b-collapse>

            <b-collapse id="collapse-filters" class="mt-1">
              <b-card>
                <div class="row">
                  <div class="col-md-6">
                    <label for="paciente">Por Pacientes:</label>
                    <Multiselect
                      :options="pacientes"
                      v-model="paciente"
                      :multiple="false"
                      selectLabel="Pressione ENTER para selecionar"
                      deselectLabel="Pressione ENTER para remover"
                      placeholder="Selecione um Paciente"
                      track-by="id"
                      label="nome"
                    >
                    </Multiselect>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <b-button @click="getData()" variant="primary"
                      >Filtrar</b-button
                    >
                  </div>
                </div>
              </b-card>
            </b-collapse>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="abr_div">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="app-calendar">
              <FullCalendar
                class="demo-app-calendar"
                :options="calendarOptions"
                :locale="'pt-br'"
              >
                <template v-slot:eventContent="arg">
                  <b>{{ arg.timeText }}</b>
                  <i>{{ arg.event.title }}</i>
                </template>
              </FullCalendar>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- START Modal Insert-->
    <b-modal
      v-model="showModal"
      id="modal-canlendar-insert"
      size="xl"
      :title="'Cadastro Agenda ->  ' + agenda.titulo"
      hide-footer
      @hidden="fechar_modal2()"
    >
      <input type="text" v-model="agenda.titulo" disabled />
      <div class="row">
        <div class="col-md-6">
          <Label>Paciente</Label>
          <select
            class="form-control"
            id="exampleFormControlSelect1"
            v-model="agenda.pacient_id"
            @change="seleciona_paciente($event)"
          >
            <option value="-1">selecione um valor</option>
            <option
              v-for="(item, index) in pacientes"
              :key="index"
              :value="item.id"
            >
              {{ item.nome }}
            </option>
          </select>
        </div>
        <div class="col-md-6">
          <Label>Profissional</Label>
          <select
            class="form-control"
            id="exampleFormControlSelect1"
            v-model="agenda.professional_id"
            @change="seleciona_prof($event)"
          >
            <option value="-1">selecione um valor</option>
            <option
              v-for="(item, index) in profissionais"
              :key="index"
              :value="item.id"
            >
              {{ item.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-md-6">
          <Label>Especialidade:</Label>
          <!-- <input
            v-model="agenda.specialty"
            type="text"
            class="form-control"
          /> -->
          <select
            class="form-control"
            id="exampleFormControlSelect1"
            v-model="agenda.specialty"
            @change="seleciona_specialty($event)"
          >
            <option value="-1">selecione um valor</option>
            <option
              v-for="(item, index) in specialtys"
              :key="index"
              :value="item.id"
            >
              {{ item.name }}
            </option>
          </select>
        </div>
        <div class="col-md-3">
          <Label>De:</Label>
          <input
            v-model="agenda.dt_ini"
            type="datetime-local"
            step="1"
            class="form-control"
          />
        </div>
        <div class="col-md-3">
          <Label>Até:</Label>
          <input
            v-model="agenda.dt_fin"
            type="datetime-local"
            step="1"
            class="form-control"
          />
        </div>
      </div>
      <div class="row mt-4 mb-4">
        <div class="col-md-12">
          <Label>Descrição</Label>
          <textarea
            v-model="agenda.desc"
            class="form-control text-left"
            cols="30"
            rows="5"
          ></textarea>
        </div>
      </div>
      <div class="row d-flex justify-content-center align-items-center mt-4">
        <div class="col-md-12 text-center">
          <button class="btn btn-success" @click.prevent="fechar_modal()">
            Salvar
          </button>
        </div>
      </div>
    </b-modal>
    <!-- END Modal Insert -->

    <!-- START Modal Edit -->
    <b-modal
      v-model="modal_edit_del"
      id="modal-calendar-edit"
      size="xl"
      :title="agenda.titulo"
      hide-footer
      @hidden="fechar_modal_edit()"
    >
      <div class="row mb-4 d-flex justify-content-center align-items-center">
        <div class="col-md-12 text-center">
          <div class="form-check">
            <input
               v-model="vtitle"
              class="form-check-input"
              type="checkbox"
              value="1"
              id="flexCheckDefault"
              @change="alter_title($event)"
            />
            <label class="form-check-label" for="flexCheckDefault">
              Alterar titulo
            </label>
          </div>
        </div>
      </div>
      <div class="row" v-if="input_title_bol">
          <div class="col-md-12">
             <input type="text" class="form-control text-left" v-model="agenda.titulo2">
          </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <Label>Paciente</Label>
          <select
            class="form-control"
            id="exampleFormControlSelect1"
            v-model="select_paciente"
            @change="seleciona_paciente($event)"
          >
            <option value="-1">selecione um valor</option>
            <option
              v-for="(item, index) in pacientes"
              :key="index"
              :value="item.id"
            >
              {{ item.nome }}
            </option>
          </select>
        </div>
        <div class="col-md-6">
          <Label>Profissional</Label>
          <select
            class="form-control"
            id="exampleFormControlSelect1"
            v-model="select_prof"
            @change="seleciona_prof($event)"
          >
            <option value="-1">selecione um valor</option>
            <option
              v-for="(item, index) in profissionais"
              :key="index"
              :value="item.id"
            >
              {{ item.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <Label>Especialidade</Label>
          <select
            class="form-control"
            id="exampleFormControlSelect1"
            v-model="agenda.specialty"
            @change="seleciona_specialty($event)"
          >
            <option value="-1">selecione um valor</option>
            <option
              v-for="(item, index) in specialtys"
              :key="index"
              :value="item.id"
            >
              {{ item.name }}
            </option>
          </select>
        </div>
        <div class="col-md-3">
          <Label>De:</Label>
          <input
            v-model="agenda.dt_ini"
            type="datetime-local"
            class="form-control"
            step="1"
          />
        </div>
        <div class="col-md-3">
          <Label>Até:</Label>
          <input
            v-model="agenda.dt_fin"
            type="datetime-local"
            class="form-control"
            step="1"
          />
        </div>
      </div>
      <div class="row mt-4 mb-4">
        <div class="col-md-12">
          <Label>Descrição</Label>
          <textarea
            v-model="agenda.desc"
            class="form-control text-left"
            cols="30"
            rows="5"
          ></textarea>
        </div>
      </div>
      <div class="row mt-4 d-flex justify-content-center align-items-center">
        <div class="col-md-6 text-center">
          <button class="btn btn-success" @click.prevent="update_obj_modal()">
            Editar
          </button>
        </div>
        <div class="col-md-6 text-center">
          <button class="btn btn-danger" @click.prevent="delete_obj_modal()">
            Excluir
          </button>
        </div>
      </div>
    </b-modal>
    <!-- END Modal Edit -->
  </div>
</template>

<style>
.fc-event,
.fc-event-dot {
  background-color: #ffffff;
}
.editable-span {
  cursor: pointer;
  border-bottom: 1px dashed #ffffff;
  color: #007bff;
}
</style>